define("discourse/plugins/discourse-ai/discourse/services/discobot-discoveries", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscobotDiscoveries extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "discovery", [_tracking.tracked], function () {
      return "";
    }))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))(); // We use this to retain state after search menu gets closed.
    // Similar to discourse/discourse#25504
    static #_2 = (() => dt7948.g(this.prototype, "lastQuery", [_tracking.tracked], function () {
      return "";
    }))();
    #lastQuery = (() => (dt7948.i(this, "lastQuery"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "discoveryTimedOut", [_tracking.tracked], function () {
      return false;
    }))();
    #discoveryTimedOut = (() => (dt7948.i(this, "discoveryTimedOut"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modelUsed", [_tracking.tracked], function () {
      return "";
    }))();
    #modelUsed = (() => (dt7948.i(this, "modelUsed"), void 0))();
    resetDiscovery() {
      this.discovery = "";
      this.discoveryTimedOut = false;
      this.modelUsed = "";
    }
  }
  _exports.default = DiscobotDiscoveries;
});